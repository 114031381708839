<script setup>
// This is a very basic implementation of the Media HTML block
const props = defineProps({
  data: Object,
  location: Object
});
const { urlRegisterFunction } = useUtils();

const mediaClass = computed(() => {
  return {
    "col-12": props.data.columns === 'center_100%' && props.data.imagePosition === 'center',
    "col-md-6": props.data.columns === 'split_50/50',
    "col-md-3": (props.data.columns === 'split_75/25' && props.data.imagePosition === 'right') || (props.data.columns === 'split_25/75' && props.data.imagePosition === 'left'),
    "col-md-9": (props.data.columns === 'split_75/25' && props.data.imagePosition === 'left') || (props.data.columns === 'split_25/75' && props.data.imagePosition === 'right'),
    "order-1": true,
    "order-md-1": props.data.imagePosition === 'left',
    "order-md-2": props.data.imagePosition === 'right'
  }
})

const contentClass = computed(() => {
  return {
    "col-12": props.data.columns === 'center_100%' && props.data.imagePosition === 'center',
    "col-md-6": props.data.columns === 'split_50/50',
    "col-md-3": (props.data.columns === 'split_25/75' && props.data.imagePosition === 'right') || (props.data.columns === 'split_75/25' && props.data.imagePosition === 'left'),
    "col-md-9": (props.data.columns === 'split_25/75' && props.data.imagePosition === 'left') || (props.data.columns === 'split_75/25' && props.data.imagePosition === 'right'),
    "order-2": true,
    "order-md-1": props.data.imagePosition === 'right',
    "order-md-2": props.data.imagePosition === 'left'    
  }
})


const desktop = computed(() => {
  return {
    "d-md-block": props.data.mobileMedia !== null,
    "d-block": props.data.mobileMedia === null,
    "d-none": props.data.mobileMedia !== null
  }
})

const goToAVC = (divisionId) => {
  if (divisionId > 0) {
    location.href = `#/reg-flow/avail-charts-lock?lid=${props.location.pulseId}&did=${divisionId}&rgnad=true`
    Pulse.app.refreshRoute();
  }
  
  // Scroll to element
  const offset = document.getElementById('www-spa-container').offsetTop;
  const deviceOffset = screen.width >= 992 ? 80 : 0;
  const scrollTopTo = offset - 130 - deviceOffset;  
  window.scrollTo({
    top: scrollTopTo,
    behavior: 'smooth',
  });
}

</script>
<template>
  <div class="block media-html">
    <div class="container">
      <div class="row flex">        
        <div :class="mediaClass">
          <!-- Render image for desktop w/ link -->
          <div class="image-wrapper w-100" v-if="data.link && data.media.mime.includes('image')">            
            <a :href="data.link" >
              <NuxtImg :src="data.media.url" :alt="data.media.alternativeText" :title="data.media.caption" :class="desktop" />
            </a>
          </div>
          <!-- Render image for desktop -->
          <div class="image-wrapper w-100" v-if="!data.link && data.media.mime.includes('image')">
            <NuxtImg :src="data.media.url" :alt="data.media.alternativeText" :title="data.media.caption" :class="desktop" />
          </div>
          <!-- Render image for mobile w/ link -->
          <div class="image-wrapper w-100" v-if="data.link && data.mobileMedia && data.mobileMedia.mime.includes('image')">
            <a :href="data.link" >
              <NuxtImg :src="data.mobileMedia.url" :alt="data.mobileMedia.alternativeText" :title="data.mobileMedia.caption" class="d-block d-sm-none" />
            </a>            
          </div>
          <!-- Render image for mobile -->
          <div class="image-wrapper w-100" v-if="!data.link && data.mobileMedia && data.mobileMedia.mime.includes('image')">
            <NuxtImg :src="data.mobileMedia.url" :alt="data.mobileMedia.alternativeText" :title="data.mobileMedia.caption" class="d-block d-sm-none" />
          </div>
          <!-- Render video -->  
          <template v-if="data.mobileMedia && data.mobileMedia.mime.includes('video') || data.media && data.media.mime.includes('video')">
            <UIVideoPlayer :data="data" />
          </template>
        </div>
        <div :class="[contentClass, 'content', 'ps-4', 'mt-4 mt-md-0']">
        <article  v-html="data.content" v-if="data.content"></article>
        <span v-if="data.locLeadGenCTAs && data.locLeadGenCTAs.togglePrimaryCTA && location.status.typeId == 1" class="card-btn-container">
          <NuxtLink @click="goToAVC(data.locLeadGenCTAs.divisionId)" class="btn btn-primary mx-2 mb-3 mb-lg-0 d-none d-md-inline-block" >{{ data.locLeadGenCTAs.primaryCTA ? data.locLeadGenCTAs.primaryCTA : 'View courses, prices & dates' }}</NuxtLink>
          <a :href="urlRegisterFunction(data.locLeadGenCTAs.divisionId ? `?lid=${location.pulseId}&did=${data.locLeadGenCTAs.divisionId}` : `?lid=${location.pulseId}`, true)" class="btn btn-primary mx-2 mb-3 mb-lg-0 d-block d-md-none" >{{ data.locLeadGenCTAs.primaryCTA ? data.locLeadGenCTAs.primaryCTA : 'View courses, prices & dates' }}</a>
        </span>
        <span v-if="data.locLeadGenCTAs && data.locLeadGenCTAs.toggleSecondaryCTA && location.status.typeId == 1" class="card-btn-container">
          <NuxtLink class="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#get-more-info">{{ data.locLeadGenCTAs.secondaryCTA ? data.locLeadGenCTAs.secondaryCTA : 'Get more info' }}</NuxtLink>
        </span></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.media-html {
  video, img {    
    border-radius: 24px;
    max-width: 100%;
    margin: 0 auto;
  }

  .content {
    ul {
      list-style-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25.286' height='18.434'%3E%3Cpath data-name='Path 90' d='M22.348 0c-4.006 4.015-8.637 8.632-12.78 12.779L2.704 6.956l-2.7 3.172 8.321 7.073 1.466 1.233 1.352-1.352c4.434-4.444 9.7-9.694 14.145-14.145L22.35-.001z' fill='%239ac92e'/%3E%3C/svg%3E");

      li {
        padding: 0 3rem 0 .5rem;
        margin-bottom: .6rem;
      }
    }
    @include media-breakpoint-down(sm) {
      text-align: center
    }
  }
   
}
</style>