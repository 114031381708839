<script setup>

const props = defineProps({
  data: Object,
  customClass: String,
  poster: {
    type: String,
    default: ''
  }
});

const video = ref(null);
const mobileVideo = ref(null);
const controls = ref(false);
const button = ref(null);

const playVideo = () => {
  controls.value = true;
  if (isMobile.value && props.data.mobileMedia && props.data.mobileMedia.mime.includes('video')) {
    mobileVideo.value.play();
  } else {   
    video.value.play();
  }
};

const buttonClass = computed(() => {
  return {
    'd-block': !controls.value,
    'd-none': controls.value,
    'd-none d-md-block': !controls.value && props.data.mobileMedia && !props.data.mobileMedia.mime.includes('video'),
    'd-block d-md-none': !controls.value && props.data.media && !props.data.media.mime.includes('video')
  };
});

const videoClass = computed(() => {
  return {
    'd-block': props.data.media && props.data.media.mime.includes('video') && !props.mobileMedia,
    'd-none d-md-block': props.data.media && props.data.media.mime.includes('video') 
                          && props.data.mobileMedia && props.data.mobileMedia.mime.includes('video') || 
                          props.data.media && props.data.media.mime.includes('video') 
                          && props.data.mobileMedia && !props.data.mobileMedia.mime.includes('video')
  };
});

// Define a breakpoint for mobile devices (e.g., 768 pixels)
const MOBILE_BREAKPOINT = 768;

// Create a ref to store whether the device is mobile
const isMobile = ref(false);

// Function to check the screen size and update isMobile ref
const checkMobile = () => {
  isMobile.value = window.innerWidth < MOBILE_BREAKPOINT;
};

// Use the onMounted hook to initially check and set isMobile
onMounted(() => {
  checkMobile();

  // Add an event listener to track screen size changes
  window.addEventListener('resize', checkMobile);
});

// Clean up the event listener when the component is unmounted
onUnmounted(() => {
  window.removeEventListener('resize', checkMobile);
});
</script>

<template>
  <div class="video-wrapper">
    <video playsinline :controls="controls"  class="video-player" v-if="data.media && data.media.mime.includes('video')"
      :class="[customClass,videoClass]" ref="video" :poster="poster">
      <source :src="data.media.url" :type="data.media.mime">
    </video>
    <video playsinline :controls="controls" v-if="data.mobileMedia && isMobile && data.mobileMedia.mime.includes('video')"
      class="d-block d-md-none video-player" ref="mobileVideo" :poster="poster">
      <source :src="data.mobileMedia.url" :type="data.mobileMedia.mime">
    </video>
    <button role="button" @click="playVideo" :class="buttonClass" ref="button">
      <span class="icon-play"></span>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.video-wrapper {
  position: relative;
  button {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    border-color: transparent;
    color: $color-secondary;
    margin: 0 auto;
    width: 95px;
    height: 95px;
    top: calc(50% - 45px);
    left: calc(50% - 45px);
    position: absolute;    
    span {
      font-size: 40px;
    }
    @include media-breakpoint-up(lg) {
      width: 150px;
      height: 150px;
      top: calc(50% - 75px);
      left: calc(50% - 75px);
      position: absolute;    
      span {
        font-size: 40px;
      }
    }
  }
}
</style>
